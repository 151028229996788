<template>
  <div class="goods-detail-wrap">
    <navbarTools v-show="isScroll" :leftArrow="true" :fixed="true"></navbarTools>
    <div class="icon-scroll-back" v-show="!isScroll">
      <div class="btn-icon-back flex" @click="goBackFn">
        <span class="icon iconfont iconfanhui ml-2"></span>
      </div>
    </div>
    <div class="bgc-fff fs0 line-height-0">
      <img :src="ruleForm.goodsThumbImage" class="w-100" style="height: 100vw" />
    </div>
    <div class="bgc-fff pt5 pb10 pl15 pr15 ml15 mr15 mt10 radius8">
      <div class="flex justify-space-between">
        <div class="c-FF3B30 fs14">
          <span>
            <span>
              <span class="font-weight fs28 mr3">{{ ruleForm.coinPrice }}</span>
              <span>币</span>
            </span>
          </span>
          <del class="c-999 ml8">￥{{ ruleForm.marketPrice }}</del>
        </div>
        <!-- 兑换商城限购规则 -->
        <div v-if="ruleForm.goodsLimitRule != 0" class="c-FE5807 fs12 flex w-90">
          <span>
            <van-icon name="info-o" class="fs14" />{{ ruleForm.goodsLimitRuleDesc }}
          </span>
        </div>
      </div>
      <div class="c-0C0500 font-weight fs16 mt10 line-height-24">
        {{ ruleForm.goodsName }}
      </div>
    </div>
    <!-- 商品简介 -->
    <div
      class="mt10 pl15 pr15 bgc-fff pt12 pb12 ml15 mr15 radius8 pb100"
      v-if="ruleForm.goodsIntroduction"
    >
      <div class="c-101010 font-weight fs18">简介</div>
      <div class="mt12 desc-box">
        <div>
          <div v-html="ruleForm.goodsIntroduction" class="goodsIntroduction"></div>
        </div>
      </div>
    </div>

    <div class="goods-detail-footer fs16 c-fff text-center iphonex-bottom">
      <!-- 兑换商城按钮 -->
      <div>
        <!-- 工钱币不足 -->
        <div
          class="footer-btn"
          v-if="Number(account?.availableCount) < Number(ruleForm.coinPrice)"
        >
          工钱币不足
        </div>
        <!-- 已下架 -->
        <div class="footer-btn" v-else-if="ruleForm.goodsStatus == 2">已下架</div>
        <div class="footer-btn active" v-else @click="buyFn">立即兑换</div>
      </div>
    </div>
    <!-- 设置购买数量 -->
    <buy-number
      ref="buyNumber"
      :goodsId="goodsId"
      :goodsLimitRule="ruleForm.goodsLimitRule"
      :goodsChannel="ruleForm.goodsChannel"
    ></buy-number>
  </div>
</template>

<script>
import buyNumber from "@/components/shop/buyNumber";
import { reactive, toRefs, getCurrentInstance, ref } from "vue";
import navbarTools from "@/components/navbarTools";
import { selfGoodsDetail } from "@/api/shop";
import { useRoute, useRouter } from "vue-router";
import { coinAccount } from "@/api/coin";
import { ua } from "@/utils/ua";
export default {
  components: {
    navbarTools,
    buyNumber,
  },
  mounted() {
    let app = getCurrentInstance();
    window.addEventListener("scroll", app.proxy.handleScroll);
  },
  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const buyNumber = ref(null);
    let app = getCurrentInstance();
    const state = reactive({
      count: 0,
      isScroll: false,
      ruleForm: {},
      goodsId: $route.query.goodsId,
      account: null,
    });
    const goBackFn = () => {
      if (ua.isAndroidApp || ua.isIosApp) {
        app.proxy.$bridge.handleMethods("goBack");
      } else {
        $router.go(-1);
      }
    };
    const handleScroll = () => {
      if (document.body.scrollTop || document.documentElement.scrollTop) {
        state.isScroll = true;
      } else {
        state.isScroll = false;
      }
    };
    const selfGoodsDetailFn = async () => {
      let rs = await selfGoodsDetail({ goodsId: state.goodsId });
      if (rs.code === 0) {
        state.ruleForm = rs.data;
      }
    };
    const buyFn = () => {
      $router.push({
        path: "/jdshop/sureOrder",
        query: {
          goodsId: state.ruleForm.goodsId,
          goodsNum: 1,
          goodsChannel: state.ruleForm.goodsChannel,
        },
      });
      // if (state.ruleForm.goodsType == '2') {
      //   $router.push({
      //     path: '/jdshop/sureOrder',
      //     query: {
      //       goodsId: state.ruleForm.goodsId,
      //       goodsNum: 1,
      //       goodsChannel: state.ruleForm.goodsChannel
      //     }
      //   })
      // } else {
      //   buyNumber.value.openFn(true)
      // }
    };
    const coinAccountFn = async () => {
      let rs = await coinAccount({
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
      });
      if (rs.code === 0) {
        state.account = rs.data;
      }
    };
    coinAccountFn();

    selfGoodsDetailFn();
    return {
      buyNumber,
      buyFn,
      goBackFn,
      handleScroll,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.goods-detail-wrap {
  position: relative;
  min-height: calc(100vh + 40px);
  .icon-scroll-back {
    position: fixed;
    left: 15px;
    top: 15px;
    padding-top: constant(safe-area-inset-top) !important; // iOs 11
    padding-top: env(safe-area-inset-top) !important; // iOs 11+ (feature)
    .btn-icon-back {
      width: 26px;
      height: 26px;
      background: rgba(0, 0, 0, 0.5);
      line-height: 26px;
      text-align: center;
      color: white;
      border-radius: 50%;
      font-size: 20px;
    }
  }
  .icon-alert {
    position: relative;
    top: 2px;
  }
  .goodsIntroduction {
    width: 100%;
  }
  ::v-deep(.goodsIntroduction img) {
    width: 100%;
  }
  .goods-detail-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 15px 20px 0;
    height: 60px;
    .footer-btn {
      height: 45px;
      width: 335px;
      line-height: 45px;
      background: #999999;
      border-radius: 3px;
      position: relative;
      bottom: 8px;
      &.active {
        background: #fc7301;
      }
    }
  }
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      background: #fff;
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
    }
  }
}
</style>
