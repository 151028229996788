<template>
  <div class="jd-buy-number-wrap">
    <van-popup
      v-model:show="numberShow"
      position="bottom"
      round
      overlay-class="number-popup"
      closeable
    >
      <div>
        <div class="line-height-55 text-center select-title c-333 fs16">
          <span>请选择</span>
        </div>
        <div class="flex justify-space-between fs16 c-333 pl15 pr15 number-select-box">
          <div class="font-weight">数量</div>
          <div>
            <van-stepper v-model="value" disable-input min="1" max="200" integer />
          </div>
        </div>
        <div class="h-49 iphonex-bottom"></div>
        <div class="iphonex-bottom jd-btn-fixed-bottom">
          <div class="pt5">
            <div
              class="radius3 bgc-fc7301 line-height-40 fs16 c-fff text-center"
              @click="goBuyFn"
            >
              立即购买
              <!-- {{
                goodsLimitRule == "" && goodsLimitRule != "0" ? "立即购买" : "立即兑换"
              }} -->
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, defineExpose } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    goodsId: {
      type: String,
    },
    goodsLimitRule: {
      type: Number,
      default: () => 0,
    },
    goodsChannel: {
      type: Number,
    },
  },
  setup(props, context) {
    const $router = useRouter();
    const state = reactive({
      value: 1,
      numberShow: false,
      goodsId: props.goodsId,
    });
    const openFn = (value) => {
      state.numberShow = value;
    };
    const goBuyFn = () => {
      $router.push({
        path: "/jdshop/sureOrder",
        query: {
          goodsId: state.goodsId,
          goodsNum: state.value,
          goodsChannel: props.goodsChannel,
        },
      });
    };
    context.expose({
      openFn,
    });
    return {
      ...toRefs(state),
      goBuyFn,
    };
  },
});
</script>
<style lang="scss">
.jd-buy-number-wrap {
  .number-popup {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .van-popup--round {
    border-radius: 6px 6px 0px 0px;
  }
  .van-popup__close-icon--top-right {
    top: 22px;
    right: 15px;
    color: rgba(51, 51, 3, 0.15);
  }
  .number-select-box {
    .van-stepper__input:read-only {
      background: rgba(0, 0, 0, 0.05);
      font-size: 16px;
      color: #333333;
      margin: 0 13px;
      width: 39px;
      height: 22px;
      line-height: 22px;
    }
    .van-stepper__minus,
    .van-stepper__plus {
      background: transparent;
    }
    .van-stepper__minus::before,
    .van-stepper__plus::before {
      background: #2b313d;
    }
    .van-stepper__minus--disabled::before,
    .van-stepper__plus--disabled::before {
      background: rgba(43, 49, 61, 0.14);
    }
  }
}
</style>
<style lang="scss" scoped>
.jd-buy-number-wrap {
  .select-title {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  }
  .number-select-box {
    line-height: 94px;
  }
  .bgc-fc7301 {
    background: #fc7301;
  }
}
</style>
